// @ts-ignore
import { useUsermaven as useUsermavenNew } from "@usermaven/react";
import { useUsermaven as useUsermavenOld } from "@usermaven-old/react";

export const useCombinedUsermaven = () => {
    const newUsermaven = useUsermavenNew();
    const oldUsermaven = useUsermavenOld();

    const combinedTrack = (...args: Parameters<typeof newUsermaven.track>) => {
        newUsermaven.track(...args);
        // @ts-ignore
        oldUsermaven.track(...args);
    };

    const combinedId = (...args: Parameters<typeof newUsermaven.id>) => {
        
        const newId = newUsermaven.id(...args);
        // @ts-ignore
        const oldId = oldUsermaven.id(...args);
        return {
            new: newId,
            old: oldId
        };
    };

    return {
        track: combinedTrack,
        id: combinedId,
        // Return other methods if needed
        newInstance: newUsermaven,
        oldInstance: oldUsermaven
    };
};