import { create } from 'zustand';

interface IUser {
  _id: string;
  onboarding: boolean;
  onboarding_data: {
    brand: boolean;
    cta: boolean;
    link: boolean;
    bio_link: boolean;
  };
  required_password: boolean;
  is_admin: boolean;
  referral_level: number;
  email_verified: boolean;
  poweredBy: boolean;
  append_hash: boolean;
  role: string;
  first_name: string | undefined;
  last_name: string | undefined;
  username: string;
  email: string;
  onboard: boolean;
  subscription_id: string;
  subscription_time: string;
  trial_expires_at: string;
  referral_id: number;
  verification_token: string;
  state: string;
  updated_at: string;
  created_at: string;
  last_login: string[];
  last_login_at: string;
  new_announcements: boolean;
  removedMembers: string[];
  fallback_cta: boolean;
  recover_token: string;
  recover_token_expiry: string;
  transfer_utm: boolean;
  profile_image: string;
  is_default_brand_and_campaign_created: boolean;
  triggers: {
    chrome_extension_dismissed: boolean;
    bio_links_onboarding: boolean;
  };
  permission: {
    brands: any[]; // Specify the type if you have a defined structure for brands
  };
  full_name: string;
  stripped_full_name: string;
  policy: {
    // List all policy fields here with their corresponding boolean types
  };
  is_trial_expired: boolean;
  trial_time: string;
  change_email?: string;
  migrated_to_v2: boolean;
  v2_onboarding: boolean;
  organization: any;
}

interface IUserStore {
  user: IUser | null;
  setUser: (user: IUser) => void;
  setProfile: (first_name: string, last_name: string) => void;
  setChangeEmail: (email: string) => void;
  updateUser: (partialUser: Partial<IUser>) => void;  // Add this new method
}

export const useUserStore = create<IUserStore>((set, get) => ({
  user: null,
  setUser: (user: IUser) => set({ user }),
  setProfile: (first_name: string, last_name: string) => {
    const currentUser = get().user;
    if (currentUser) {
      set({ user: { ...currentUser, first_name, last_name } });
    }
  },
  setChangeEmail: (email: string) => {
    const currentUser = get().user;
    if (currentUser) {
      set({ user: { ...currentUser, change_email: email } });
    }
  },
  updateUser: (partialUser: Partial<IUser>) => {
    const currentUser = get().user;
    if (currentUser) {
      set({ user: { ...currentUser, ...partialUser } });
    }
  },
}));
