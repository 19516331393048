import { CampaignsColumnsMenu } from '@/components/Menus/CampaignsColumnsMenu/CampaignsColumnsMenu';
import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  faBoxArchive,
  faChevronDown,
  faCircleExclamation,
  faEllipsisVertical,
  faLink,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Button,
  Checkbox,
  Flex,
  Grid,
  Menu,
  Popover,
  Space,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import CustomDateMenu from '@/components/Menus/DateMenu/CustomDateMenu';

export function CampaignsHeader() {
  const theme = useMantineTheme();
  const [archive, setArchive, search, setSearch, setDate, setPage] = useCampaignsStore((state) => [
    state.archive,
    state.setArchive,
    state.search,
    state.setSearch,
    state.setDate,
    state.setPage,
  ]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const navigate = useNavigate();
  useEffect(() => {
    setSearch('')
  }, [])

  const handleDateChange = (startDate: string | null, endDate: string | null, granularity: string) => {
    setPage(1);
    if (!startDate || !endDate) {
      // Handle "All time" case
      setDate({
        label: 'All time',
        value: `${startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString()}_${endOfDay(new Date()).toISOString()}`
      });
    } else {
      setDate({
        label: 'Custom',
        value: `${startDate}_${endDate}`
      });
    }
  };

  return (
    <Grid p={'lg'}>
      <Grid.Col span={5}>
        <Flex align={'center'}>
          {(defaultPermissions && checkPermission(defaultPermissions, ['add_campaign'])) && <Button size="xs" onClick={() => navigate(`/campaigns/${defaultWorkspace?._id}/setup`)}>
            Create new
          </Button>}
          <TextInput
            w={160}
            size="xs"
            value={search}
            onChange={(event) => {
              setPage(1)
              setSearch(event.currentTarget.value)
            }}
            ml={8}
            placeholder="Search campaign"
            leftSection={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{
                  color: theme.colors.gray[5],
                }}
              />
            }
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={2}>
        <></>
      </Grid.Col>
      <Grid.Col span={5}>
        <Flex align={'center'} justify={'end'}>
          {/* <DateMenu onChange={setDate} /> */}
          <CustomDateMenu
            showButtons={{
              all_time: true,
              last_24_hours: true,
              last_7_days: true,
              last_14_days: true,
              last_30_days: true,
              last_90_days: false,
              last_6_months: true,
              last_1_year: true,
              this_month: true,
              last_month: true,
              "7D": false,
              "14D": false,
              "30D": false,
              "3M": false,
              "6M": false,
              "12M": false,
              "no-range": false,
              custom: true
            }}
            onDateChange={handleDateChange}
            value={[null, null]}
            withGranularity={false}
          />
          <Space w={12} />
          <CampaignsColumnsMenu />
          <Space w={12} />
          {(defaultPermissions && checkPermission(defaultPermissions, ['add_campaign'])) && (
            <Popover withArrow shadow="sm">
              <Popover.Target>
                <Button
                  variant="outline"
                  color="gray"
                  size="xs"
                  rightSection={<FontAwesomeIcon icon={faChevronDown} />}
                >
                  {archive ? 'Archived Campaigns' : 'Active Campaigns'}
                </Button>
              </Popover.Target>
              <Popover.Dropdown w={200}>
                <Flex
                  align={'center'}
                  fz={'xs'}
                  style={{ cursor: 'pointer' }}
                  py={6}
                  onClick={() => setArchive(false)}
                >
                  <Checkbox
                    mb={2}
                    size="xs"
                    styles={{
                      input: { cursor: 'pointer' },
                      label: { cursor: 'pointer' },
                    }}
                    label="Active Campaigns"
                    checked={!archive}
                    onChange={() => setArchive(false)}
                  />
                </Flex>
                <Flex
                  align={'center'}
                  fz={'xs'}
                  style={{ cursor: 'pointer' }}
                  py={6}
                  onClick={() => setArchive(true)}
                >
                  <Checkbox
                    mb={2}
                    size="xs"
                    styles={{
                      input: { cursor: 'pointer' },
                      label: { cursor: 'pointer' },
                    }}
                    label="Archived Campaigns"
                    checked={archive}
                    onChange={() => setArchive(true)}
                  />
                </Flex>
              </Popover.Dropdown>
            </Popover>
          )}
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
