import { faCircleCheck, faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Center,
  Divider,
  Group,
  Paper,
  Stepper,
  Title,
  Text,
  Flex,
  Switch,
  Loader,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { WhitelabelDomainSettings } from './WhitelabelDomainSettings';
import { WhitelabelEmailSettings } from './WhitelabelEmailSettings';
import { WhitelabelGeneralSettings } from './WhitelabelGeneralSettings';
import { WhitelabelService } from '@/services/WhitelabelService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { UpgradePlanMessage } from '@/components/Billing/UpgradePlanMessage';

export function WhitelabelPage() {
  const [defaultWorkspace, setDefaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace, state.setDefaultWorkspace]);
  useDocumentTitle('Whitelabel Settings | ' + defaultWorkspace?.agency?.name);

  const [plan] = usePlanStore((state) => [state.plan]);
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();

  const fetchWhitelabel = async () => {
    setLoading(true);
    await new WhitelabelService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
          if (res.data.data.is_whitelabel_setup_completed) {
            setActive(3);
          }
        }
      })
      .catch((err) => { });
    setLoading(false);
  };

  const handleActivateWhitelabel = async () => {
    await new WhitelabelService()
      .activate(defaultWorkspace?._id || '', {
        _id: data?._id,
        is_whitelabel_enabled: !data.is_whitelabel_enabled,
      })
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
          let workspace = defaultWorkspace;
          if (workspace) {
            workspace.organization = res.data.organization
            setDefaultWorkspace(workspace);
          }
        }
      })
      .catch((err) => { });
  };

  const handleNextStep = () => {
    setActive((current) => {
      const nextActive = current < 3 ? current + 1 : current;
      if (nextActive === 3) {
        // This means we've just completed the email settings step
        handleActivateWhitelabel();
      }
      return nextActive;
    });
  };

  useEffect(() => {
    fetchWhitelabel();
  }, []);

  const isLastStep = active === 3;

  return (
    <>
      {!plan?.limits?.whitelabel ? (
        <UpgradePlanMessage />
      ) : (
        <Box
          style={{
            backgroundColor: 'var(--mantine-color-white)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            height: '100%',
            flexGrow: 1,
          }}
          ml={16}
          mr={16}
        >
          <Box p={16}>
            <Flex justify="space-between" align="center">
              <Box style={{ width: '33%' }} /> {/* Spacer */}
              <Center style={{ flex: 1 }}>
                <Title order={5}>White-label Agency Setup</Title>
              </Center>
              <Box style={{ width: '33%', display: 'flex', justifyContent: 'flex-end' }}>
                {isLastStep && data && data.is_whitelabel_setup_completed && (
                  <Button
                    onClick={handleActivateWhitelabel}
                    color={data && data.is_whitelabel_enabled ? 'red' : '#003aa9'}
                  >
                    {data && data.is_whitelabel_enabled ? 'Disable' : 'Enable'}
                  </Button>
                )}
              </Box>
            </Flex>
          </Box>
          <Divider color="gray.2" />
          {loading ? (
            <Flex mt={'xl'} justify={'center'}>
              <Loader size={'sm'} />
            </Flex>
          ) : (
            <>
              <Paper p={32}>
                <Stepper
                  active={active}
                  onStepClick={setActive}
                  allowNextStepsSelect={data?._id && data?.is_whitelabel_setup_completed ? true : false}
                >
                  <Stepper.Step label="General Settings" description="Agency name, logo, etc.">
                    <WhitelabelGeneralSettings nextStep={handleNextStep} />
                  </Stepper.Step>
                  <Stepper.Step
                    label="Domain Settings"
                    description="Your agency and branded links domain."
                  >
                    <WhitelabelDomainSettings nextStep={handleNextStep} prevStep={prevStep} />
                  </Stepper.Step>
                  <Stepper.Step label="Email Settings" description="Set up SMTP">
                    <WhitelabelEmailSettings nextStep={handleNextStep} prevStep={prevStep} />
                  </Stepper.Step>
                  <Stepper.Completed>
                    <Center mt={32}>
                      <Flex justify="center" align={'center'} direction={'column'}>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="2xl"
                          style={{
                            fontSize: '3rem',
                            color: 'var(--mantine-color-green-6)',
                          }}
                        />
                        <Text fw={600} fz={14} my="md">
                          Your whitelabel setup has been completed.{' '}
                        </Text>

                        <Divider my={'md'} />
                        <Group justify="center" >
                          {data?.is_whitelabel_enabled && (
                            <>
                              {!defaultWorkspace?.agency?.is_whitelabeled ? (
                                <Button onClick={() => window.open(`https://${defaultWorkspace?.agency?.app_domain}`, '_blank')}>
                                  Switch to Whitelabel
                                </Button>
                              ) : (
                                <Button onClick={() => window.open(`https://replug.io`, '_blank')}>
                                  Switch to Replug
                                </Button>
                              )}
                            </>
                          )}
                          <Button variant="default" onClick={prevStep}>
                            Go Back
                          </Button>
                        </Group>
                      </Flex>
                    </Center>
                  </Stepper.Completed>
                </Stepper>
              </Paper>
            </>
          )}
        </Box>
      )}
    </>
  );
}
