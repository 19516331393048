import { RetargetingPixelModal } from '@/components/Modals/RetargetingPixelModal/RetargetingPixelModal';
import { useCampaignSetupStore } from '@/stores/useCampaignSetupStore';
import { usePixelsStore } from '@/stores/usePixelsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Button, Center, Divider, MultiSelect, Stack, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { CallToActionTypeEnum } from '@replug/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useCombinedUsermaven } from '@/hooks/useCombinedUsermaven';


export const CampaignRetargetingPixels = ({ form }: { form: any; nextStep: () => void }) => {
  const navigate = useNavigate();

  const [storeCampaign, createLoading] = useCampaignSetupStore((state) => [
    state.storeCampaign,
    state.createLoading,
  ]);
  const {track} = useCombinedUsermaven()
  const [pixlels, fetchPixels, setOpened] = usePixelsStore((state) => [
    state.pixels,
    state.fetchPixels,
    state.setSetupModal,
  ]);
  const [ setPage ] = useCampaignsStore((state) => [ state.setPage]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useEffect(() => {
    fetchPixels(defaultWorkspace?._id || '');
  }, []);

  const handleSaveCampaign = async () => {
    console.log(form.values);

    if (!form.values.retargeting_ids || form.values.retargeting_ids.length === 0) {
      // set form error

      form.setFieldError('retargeting_ids', 'Please select a retargeting pixel');

      notifications.show({
        color: 'red',
        message: 'Please select a retargeting pixel',
      });
      return;
    }

    if (
      form.values.is_call_to_action_enabled &&
      form.values.call_to_action.type !== CallToActionTypeEnum.Form &&
      !form.values.call_to_action.button_url
    ) {
      notifications.show({
        color: 'red',
        message: 'Please add call to action button URL.',
      });
      return;
    }

    const res = await storeCampaign(defaultWorkspace?._id || '', form.values);
    if (res) {
      track('campaign_created', { campaign: form.values.name, type: 'retargeting_pixel' })
      setPage(1)
      navigate(`/campaigns/${defaultWorkspace?._id}`);
    }
  };

  return (
    <>
      {' '}
      <RetargetingPixelModal />
      <Title order={5} px={24} py={16}>
        Retargeting Pixels
      </Title>
      <Divider color="gray.2" />
      <Stack p={24}>
        <MultiSelect
          data={pixlels.map((pixel) => {
            return { label: pixel.name, value: pixel._id };
          })}
          placeholder="Select a retargeting pixel"
          {...form.getInputProps('retargeting_ids')}
        />
        <Divider color="gray.5" label="OR" />
        <Button variant="outline" color="blue" onClick={() => setOpened(true)}>
          Create a new Retargeting Pixel
        </Button>
        <Center>
          <Button
            loading={createLoading}
            disabled={createLoading}
            mt={32}
            onClick={handleSaveCampaign}
          >
            Save Campaign
          </Button>
        </Center>
      </Stack>
    </>
  );
};
