import { WHITELABEL_APP_DOMAIN, WHITELABEL_SHORTENER_DOMAIN } from '@/constants/Constants';
import { WhitelabelService } from '@/services/WhitelabelService';
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  CopyButton,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  Paper,
  Space,
  Table,
  Text,
  TextInput,
  Tooltip,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { ensureHttps } from '@/utils/LinkUtility';

const isValidUrl = (value: string) => {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
};

const schema = z.object({
  _id: z.string().min(1, { message: 'Invalid ID' }),
  app_domain: z.string().min(1, { message: 'App Domain name should be valid domain.' }),
  shortener_domain: z.string().min(1, { message: 'Shortener Domain should be a valid domain' }),
  is_app_domain_connected: z.boolean(),
  is_shortener_domain_connected: z.boolean(),
  whitelabel_404_url: z.string().refine(isValidUrl, { message: 'Branded 404 URL should be a valid URL' }),
  whitelabel_branded_url: z.string().refine(isValidUrl, { message: 'Branded URL should be a valid URL' }),
});


export const WhitelabelDomainSettings = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => void;
  prevStep: () => void;
}) => {
  // Domain settings form

  const form = useForm({
    initialValues: {
      _id: '',
      app_domain: '',
      shortener_domain: '',
      is_app_domain_connected: false,
      is_shortener_domain_connected: false,
      whitelabel_404_url: '',
      whitelabel_branded_url: ''
    },
    validate: zodResolver(schema),
  });

  const [appDomain] = useDebouncedValue(form.values.app_domain, 200);
  const [shortenerDomain] = useDebouncedValue(form.values.shortener_domain, 200);

  const [appDomainConnectedValue, setAppDomainConnectedValue] = useState(form.values.app_domain);
  const [shortenerDomainConnectedValue, setShortenerDomainConnectedValue] = useState(
    form.values.shortener_domain
  );

  const [appDomainHost, setAppDomainHost] = useState('@');
  const [shortenerDomainHost, setShortenerDomainHost] = useState('@');
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);


  const renderAppDomainRecordsTable = () => {
    return (
      <>
        <Table my="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Type</Table.Th>
              <Table.Th>Host</Table.Th>
              <Table.Th>Value</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>CNAME</Table.Td>
              <Table.Td>
                <Flex align={'center'}>
                  <Input value={appDomainHost} w="auto" mr={'xs'} />
                  <CopyButton value={appDomainHost} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </Table.Td>
              <Table.Td>
                <Flex align={'center'}>
                  <Input value={WHITELABEL_APP_DOMAIN} w="auto" mr={'xs'} />
                  <CopyButton value={WHITELABEL_APP_DOMAIN} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </>
    );
  };

  const renderShortenerDomainURLTable = () => {
    return (
      <>
        <Table my="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Type</Table.Th>
              <Table.Th>Host</Table.Th>
              <Table.Th>Value</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>CNAME</Table.Td>
              <Table.Td>
                <Flex align={'center'}>
                  <Input value={shortenerDomainHost} w="auto" mr={'xs'} />
                  <CopyButton value={shortenerDomainHost} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </Table.Td>
              <Table.Td>
                <Flex align={'center'}>
                  <Input value={WHITELABEL_SHORTENER_DOMAIN} w="auto" mr={'xs'} />
                  <CopyButton value={WHITELABEL_SHORTENER_DOMAIN} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </>
    );
  };

  const renderDNSREcordInstructions = (type: string = 'App') => {
    return (
      <>
        <Alert color="yellow" icon={<FontAwesomeIcon icon={faLightbulb} />}>
          <Text fz={13}>
            You'll need to access your domain hosting service to configure domain. Copy Host
            and Value for record and add them to your domain manager. Refer to{' '}
            <Anchor fz={'13'} href="https://docs.replug.io/article/164-custom-domain-setup" target="_blank" c="primary" fw={'600'}>
              this article
            </Anchor>{' '}
            to learn more.
            <br />
            <br />
            DNS Configuration Changes can take up to <strong>24 hours to propagate</strong> fully.
            If you're using Cloudflare, ensure that the Cloudflare Proxy option is disabled.
          </Text>
        </Alert>
      </>
    );
  };

  const findDomainHost = (domain: string, type: string = 'app') => {
    new WhitelabelService()
      .findHost(defaultWorkspace?._id || '', domain)
      .then((res) => {
        if (res.data.status) {
          if (type === 'app') {
            setAppDomainHost(res.data.data ? res.data.data : '@');
          } else {
            setShortenerDomainHost(res.data.data ? res.data.data : '@');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle app domain verification

  const handleAppDomainVerification = async () => {
    console.log('shortener domain verification');
    if (!form.values.app_domain) {
      form.setFieldError('app_domain', 'Please enter an app domain');
    }

    await new WhitelabelService()
      .validateAndSaveAppDomain(defaultWorkspace?._id || '', { domain: appDomain, _id: form.values._id })
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          notifications.show({
            message: res.data.message,
            color: 'green',
          });

          form.setFieldValue('is_app_domain_connected', true);
          form.setFieldValue('app_domain', res.data.data.app_domain);
          setAppDomainConnectedValue(res.data.data.app_domain);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notifications.show({
            message: err.response.data.message,
            color: 'red',
          });
        }
      });
  };

  // handle shortener domain verification

  const handleShortenerDomainVerification = async () => {
    console.log('shortener domain verification');

    // Validate specific fields
    const shortenerDomainError = await form.validateField('shortener_domain');
    const whitelabel404UrlError = await form.validateField('whitelabel_404_url');
    const whitelabelBrandedUrlError = await form.validateField('whitelabel_branded_url');

    if (!form.values.shortener_domain) {
      form.setFieldError('shortener_domain', 'Please enter a domain');
      return;
    }
    if (!form.values.whitelabel_branded_url) {
      form.setFieldError('whitelabel_branded_url', 'Please enter a branded URL');
      return;
    }

    if (!form.values.whitelabel_404_url) {
      form.setFieldError('whitelabel_404_url', 'Please enter a 404 URL');
      return;
    }


    await new WhitelabelService()
      .validateAndSaveShortenerDomain(defaultWorkspace?._id || '', { domain: shortenerDomain, _id: form.values._id, whitelabel_404_url: form.values.whitelabel_404_url, whitelabel_branded_url: form.values.whitelabel_branded_url })
      .then((res) => {
        if (res.data.status) {
          console.log(res.data);
          notifications.show({
            message: res.data.message,
            color: 'green',
          });
          form.setFieldValue('is_shortener_domain_connected', true);
          form.setFieldValue('shortener_domain', res.data.data.shortener_domain);
          setShortenerDomainConnectedValue(res.data.data.shortener_domain);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notifications.show({
            message: err.response.data.message,
            color: 'red',
          });
        }
      });
  };

  const fetchWhitelabel = async () => {
    await new WhitelabelService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          const {
            _id,
            app_domain,
            shortener_domain,
            is_app_domain_connected,
            is_shortener_domain_connected,
            whitelabel_404_url,
            whitelabel_branded_url
          } = res.data.data;
          form.setValues({
            _id,
            app_domain,
            shortener_domain,
            is_app_domain_connected,
            is_shortener_domain_connected,
            whitelabel_404_url,
            whitelabel_branded_url
          });
          setAppDomainConnectedValue(app_domain);
          setShortenerDomainConnectedValue(shortener_domain);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNextStep = () => {
    if (!form.values.is_app_domain_connected && !form.values.is_shortener_domain_connected) {
      notifications.show({
        color: 'red',
        message: 'Please connect your app and shortener domain.',
      });
    }
    nextStep();
  };
  useEffect(() => {
    if (appDomain) {
      findDomainHost(appDomain);
    }
  }, [appDomain]);

  useEffect(() => {
    if (shortenerDomain) {
      findDomainHost(shortenerDomain, 'shortener');
    }
  }, [shortenerDomain]);

  useEffect(() => {
    fetchWhitelabel();
  }, []);
  return (
    <Paper my="md">
      <Grid gutter="md" mb="md">
        <Grid.Col span={12}>
          {renderDNSREcordInstructions('App')}
        </Grid.Col>
      </Grid>
      <Grid gutter="md" mb="md">
        <Grid.Col span={6}>
          <Alert color="blue" title="App Domain DNS Record">
            <Text fz={13}>
              By adding your custom domain, you can personalize the domain name for example{' '}
              <strong>(app.yourbrand.com)</strong> to access the Replug platform instead of using{' '}
              <strong>(app.replug.io)</strong>.
            </Text>
          </Alert>
        </Grid.Col>
        <Grid.Col span={6}>
          <Alert color="blue" title="Branded Shortener DNS Record">
            <Text fz={13}>
              By adding your branded link domain, you can personalize the domain for example{' '}
              <strong>(link.yourbrand.com)</strong> used for shortening the URL when no custom domain is
              connected with the campaign.
            </Text>
          </Alert>
        </Grid.Col>
      </Grid>
      <Grid py="md" gutter="xl">
        <Grid.Col span={6}>
          <Box w={'93%'}>
            <Flex direction={'column'}>
              <TextInput
                label="App Domain URL"
                description="URL your client and customers will use to access the application"
                placeholder="e.g app.youragencydomain.com"
                {...form.getInputProps('app_domain')}
              />

              {form.values.is_app_domain_connected && (
                <>
                  <Flex my={'md'} align={'center'} bg="gray.1" p={8}>
                    <Text c="green" fw={600} fz={14} mr={4}>
                      Connected domain:{' '}
                    </Text>
                    <Text fz={14}>{appDomainConnectedValue}</Text>
                  </Flex>
                  <Divider />
                </>
              )}
              <Box h={202} /> {/* Adjust the height as needed */}
              {renderAppDomainRecordsTable()}
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box w={'93%'}>
            <Flex direction={'column'}>
              <TextInput
                label="Branded Shortener Domain URL"
                description="Your links will be shortened using this domain."
                placeholder="e.g yourshortenerdomain.com"
                {...form.getInputProps('shortener_domain')}
              />
              {form.values.is_shortener_domain_connected && (
                <>
                  <Flex my={'md'} align={'center'} bg="gray.1" p={8}>
                    <Text c="green" fw={600} fz={14} mr={4}>
                      Connected domain:{' '}
                    </Text>
                    <Text fz={14}>{shortenerDomainConnectedValue}</Text>
                  </Flex>
                  <Divider />
                </>
              )}
              <TextInput
                my={10}
                label="Branded domain"
                description="Users will be redirected to this URL when they open your root address of branded domain."
                placeholder="Your main website URL ie. https://yourdomain.com"
                {...form.getInputProps('whitelabel_branded_url')}
                onChange={(event) => {
                  const value = event.currentTarget.value;
                  form.setFieldValue('whitelabel_branded_url', ensureHttps(value));
                }}
              />
              <TextInput
                my={10}
                label="404 Redirect URL"
                description="When a user opens a URL that doesn't exist, they will be redirected to this URL."
                placeholder="Redirect URL to branded 404 page"
                {...form.getInputProps('whitelabel_404_url')}
                onChange={(event) => {
                  const value = event.currentTarget.value;
                  form.setFieldValue('whitelabel_404_url', ensureHttps(value));
                }}
              />
              {/* <Divider my={20}/> */}
              {renderShortenerDomainURLTable()}

            </Flex>
          </Box>
        </Grid.Col>
      </Grid>

      <Grid gutter="md" mb="md">
        <Grid.Col span={6}>
          <Flex justify={'center'} mt="md">
            <Button onClick={handleAppDomainVerification}>Verify and Save Domain</Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={6}>
          <Flex justify={'center'} mt="md">
            <Button onClick={handleShortenerDomainVerification}>Verify and Save Domain</Button>
          </Flex>
        </Grid.Col>
      </Grid>

      <Divider my={'md'} />
      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button onClick={handleNextStep}>Next step</Button>
      </Group>
    </Paper>
  );
};
