import ReactDOM, { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { APP_ENVIRONMENT, PADDLE_VENDOR_ID, USERMAVEN_PROJECT_ID, USERMAVEN_TRACKING_HOST } from './constants/Constants';

// @ts-ignore
import { createClient, UsermavenProvider } from "@usermaven/react";
import { createClient as createClientOld, UsermavenProvider as UsermavenProviderOld } from "@usermaven-old/react";

Sentry.init({
  dsn: 'https://e399d70552cedfde03832e57135fbd0c@sentry-onpremise.replug.io/4',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Paddle setup
if (APP_ENVIRONMENT != 'production') {
  // @ts-ignore
  Paddle.Environment.set('sandbox')
}
// @ts-ignore 
Paddle.Setup({
  vendor: PADDLE_VENDOR_ID,
  eventCallback: function (data: any) {
    if (data.event === 'Checkout.Complete') {
      console.log('Checkout complete', data);
    } else if (data.event === "Checkout.Close") {
      console.log('Checkout closed', data);
    }
  }
});

const usermavenClient = createClient({
  key: 'UM8Fm4BG1W',
  trackingHost: 'https://um.replug.io',
  autocapture: true,
  namespace: 'usermaven_new',
});

const usermavenClientOld = createClientOld({
  key: 'UMiC4G66M3',
  tracking_host: 'https://um-v2.replug.io',
  autocapture: true,
});


ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <UsermavenProviderOld client={usermavenClientOld}>
      <UsermavenProvider client={usermavenClient}>
        <App />
      </UsermavenProvider>
    </UsermavenProviderOld>
  </>
);
