import { useLocation, useNavigate } from 'react-router-dom';
import { AuthCarousel } from '@/components/Auth/AuthCarousel';
import {
  Anchor,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import ReplugLogo from '../../assets/images/logos/replug-logo.svg';
import classes from './auth.module.css';
import axios from 'axios';
import { API_URL, GOOGLE_RECAPTCHA_SITE_KEY } from '@/constants/Constants';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { useUserStore } from '@/stores/useUserStore';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { APP_ENVIRONMENT } from '@/constants/Constants';
import { useCombinedUsermaven } from '@/hooks/useCombinedUsermaven';
import { getAppPrivacyPolicy, getAppTermsOfService } from '@/utils/CommonUtility';

export function RegisterPage() {
  const [setUser] = useUserStore((state) => [state.setUser]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { track } = useCombinedUsermaven()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subscriptionName = searchParams.get('subscription_name') || '';

  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      business_name: '',
    },

    validate: {
      first_name: (value) => (value.trim().length > 0 ? null : 'First name is required'),
      last_name: (value) => (value.trim().length > 0 ? null : 'Last name is required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.trim().length > 0 ? null : 'Password is required'),
      business_name: (value) => (value.trim().length > 0 ? null : 'Business name is required'),
    },
  });

  const loadRecaptchaScript = () => {
    // Remove any existing reCAPTCHA scripts to avoid duplicates
    const existingScript = document.querySelector('script[src*="recaptcha"]');
    if (existingScript) return;

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const getRecaptchaToken = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        notifications.show({
          color: 'red',
          message: 'reCAPTCHA failed to load. Please refresh the page.',
        });
        reject('reCAPTCHA not loaded');
        return;
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(GOOGLE_RECAPTCHA_SITE_KEY || '', {
            action: 'register'
          })
          .then(resolve)
          .catch((error: Error) => {
            notifications.show({
              color: 'red',
              message: 'reCAPTCHA verification failed. Please try again.',
            });
            reject(error);
          });
      });
    });
  };

  const handleRegisterAccount = async (formValues: any) => {
    try {
      setLoading(true);
      // Get reCAPTCHA token
      const recaptchaToken = await getRecaptchaToken();
      // Prepare the final payload
      const finalPayload = {
        ...formValues,
        recaptcha_token: recaptchaToken
      };
      // Add specific axios config to see what's happening
      const res = await axios.post(`${API_URL}/auth/register`, finalPayload, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        validateStatus: function (status) {
          return true; // Always return promise instead of throwing error
        }
      });
      if (res.data.status === 'success') {
        setAuthTokenLocalStorage(res.data.authorisation.token);
        setUser(res.data.user);
        navigate('/verification');
        track('signed_up', {});
      } else {
        notifications.show({
          color: 'red',
          message: res.data.message || 'Registration failed',
        });
      }
    } catch (err: any) {
      if (err.response?.status === 400 || err.response?.status === 422) {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      } else {
        notifications.show({
          color: 'red',
          message: `Registration error: ${err.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/google`);
      if (response.data.status === 'success' && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Failed to initiate Google login'
      });
    }
  };

  return (
    <Paper
      style={{
        backgroundColor: '#f2f6f7',
        overflow: 'hidden', // Prevent scrolling
      }}
    >
      <Grid>
        <Grid.Col
          span={3.5}
          style={{
            height: '100%',
          }}
        >
          <Flex mt={16} mx={16} direction="column" className={classes.authContainer}>
            <Box className={classes.authLogoContainer}>
              <Image w={122} h={36} src={ReplugLogo} />
            </Box>
            <Stack h="100%" justify="space-between">
              <Box p={16} px={32}>
                <Title order={4} ta="center" pt={20} pb={40}>
                  {' '}
                  Create Account{' '}
                </Title>

                {/* Add Google Sign In Button */}
                {/* {APP_ENVIRONMENT !== 'production' && (<> */}

                <Button
                  variant="outline"
                  fullWidth
                  leftSection={
                    <Image
                      src="https://www.google.com/favicon.ico"
                      width={20}
                      height={20}
                      alt="Google"
                    />
                  }
                  onClick={handleGoogleLogin}
                  mb={24}
                >
                  Sign up with Google
                </Button>

                <Divider
                  label="Or continue with email"
                  labelPosition="center"
                  mb={24}
                />
                {/* </>)} */}

                <form onSubmit={form.onSubmit(handleRegisterAccount)}>
                  <Grid mb={16}>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        placeholder="e.g John"
                        {...form.getInputProps('first_name')}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        placeholder="e.g Carl"
                        {...form.getInputProps('last_name')}
                      />
                    </Grid.Col>
                  </Grid>
                  <TextInput
                    mb={16}
                    label="Email"
                    placeholder="e.g john.carl@email.com"
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    mb={16}
                    label="Password"
                    placeholder="e.g ********"
                    {...form.getInputProps('password')}
                  />
                  <TextInput
                    mb={16}
                    label="Business name"
                    placeholder="e.g Replug"
                    {...form.getInputProps('business_name')}
                  />
                  <Box
                    mt={40}
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button disabled={loading} loading={loading} type="submit" w={140}>
                      Sign up
                    </Button>
                  </Box>
                  <Flex p={16} pt={30} direction="column" align="center">
                    <Text pb={8} size="sm">
                      Already have account{' '}
                      <Anchor onClick={() => navigate('/login')} fw="bold">
                        Sign in
                      </Anchor>
                      .
                    </Text>
                  </Flex>
                </form>
              </Box>
              <Flex p={16} direction="column" align="center">
                <Text pb={16} size="sm" ta="center" c="dimmed">
                  By signing up, You agree to our{' '}
                  <Anchor onClick={() => window.open(getAppTermsOfService(), '_blank')}>
                    terms of service
                  </Anchor>{' '}
                  and{' '}
                  <Anchor onClick={() => window.open(getAppPrivacyPolicy(), '_blank')}>
                    privacy policy
                  </Anchor>
                  .
                </Text>
              </Flex>
            </Stack>
          </Flex>
        </Grid.Col>
        <Grid.Col span={8.5}>
          <AuthCarousel />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
